import { filter, sortBy } from "nuxt-lodash/dist/runtime/lodash"
import type { ColumnItem, LenghtAwarePagination } from "~/models/Pagination"
import type { ServerPaginator } from "~/models/ServerPaginator"

export function usePagination<T>(url: string, method: 'GET' | 'POST', elementId?: string, columns?: ColumnItem[]) {

    const paginator = ref<LenghtAwarePagination<T>>({data: [] as T[]} as LenghtAwarePagination<T>)
    const page = ref<number>(1)
    const filters = ref<Record<string, any>>({})
    const sortBy = ref(null)
    const descending = ref(false)
    const router = useRouter()
    const {loading, get, post} = useOFetchCustom(url)
    const notify = useNotification()
    const i18n = useI18n()
    const {scrollToAnchor} = useAnchorScroll({
        toAnchor: {
            scrollOptions: {
                behavior: 'smooth',
                offsetTop: -250
            }
        }
    })

    const qTablePaginatorConnector = computed({
        get: () => {
            return {
                page: page.value,
                items: paginator.value.data,
                rowsPerPage: paginator.value.per_page,
                rowsNumber: paginator.value.total,
                sortBy: sortBy.value,
                descending: descending.value
            }
        },
        set: (qTablePaginator: any) => {
            page.value = qTablePaginator.page
            paginator.value.per_page = qTablePaginator.rowsPerPage
            sortBy.value = qTablePaginator.sortBy
            descending.value = qTablePaginator.descending
        }
    })

    const pages = computed(() => {
        const rowsPerPage = paginator.value.per_page!
        const rows = paginator.value.total!

        return Math.ceil(rows / rowsPerPage)
    })

    const addFilter = (key: string, value: any) => {
        filters.value[key] = value
    }

    const removeFilter = (key: string) => {
        delete filters.value[key]
    }

    const clearAllFilters = () => {
        filters.value = {}
    }

    const handlePaginate = (options?: {onSuccess?: (res: any) => void, onError?: (err: any) => void, noScroll?: Boolean}) => {
        if(elementId != undefined && (!options?.noScroll || options?.noScroll == false)) {
            scrollToAnchor(elementId)
        }

        applySort()
        searchBy()

        if(method === 'GET') {
            get({page: page.value, perPage: paginator.value.per_page, ...filters.value}, {
                onSuccess: ({data}) => {
                    paginator.value = data
                    if(options?.onSuccess != undefined) {
                        options.onSuccess(data)
                    }
                },
                onError:(response:any)=>{
                    if(response.status == 403) {
                        router.push('/store')
                        notify("negative",i18n.t('messages.not_have_permission'))
                    }
                }
            })
        } else {
            post({page: page.value, perPage: paginator.value.per_page, ...filters.value}, {
                onSuccess: ({data}) => {
                    paginator.value = data
                    if(options?.onSuccess != undefined) {
                        options.onSuccess(data)
                    }
                }
            })
        }
    }

    const preventEmptyDataWhenDelete = () => {
        if( ((paginator.value.total - 1) * 1.0)/paginator.value.per_page == 1)
        {
            page.value = page.value - 1
        }
    }

    async function onQTableRequest(props?: any) {
        if(props !== undefined) {
            qTablePaginatorConnector.value = props.pagination
            await nextTick()
        }
        handlePaginate()
    }

    function applySort() {
        removeFilter('sortBy')
        removeFilter('sortOrder')
        if(sortBy.value != null) {
            addFilter('sortBy', sortBy.value)

            if(descending.value != null) {
                addFilter('sortOrder', descending.value ? 'desc' : 'asc')
            }
        }
    }

    function searchBy(): void {
        if(!columns || columns.length == 0) {   
            return
        }

        const filterables = columns.filter(c => c.filterable == true)

        if(filterables.length == 0) {
            return
        }

        const searchBy = columns.filter(c => c.filterable == true).map(c => c.data || c.name).join('|')

        removeFilter('searchBy')
        addFilter('searchBy', searchBy)
    }

    return {paginator, qTablePaginatorConnector, pages, page, loading, filters, handlePaginate, addFilter, removeFilter, clearAllFilters, preventEmptyDataWhenDelete, onQTableRequest}
}
